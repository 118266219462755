<template>
  <el-row>
    <el-col :lg="{span: 10, offset: 7}">
      <el-card class="overflow-visible" v-loading.body="isLoading">
        <template #header>
          <div>
            <h2 class="heading page-heading">
              {{ $t('page_description_edit.title') }}
            </h2>
            <CurrentUnitInfo />
          </div>
        </template>
        <template #default>
          <el-row>
            <el-col :span="24">
              <v-text-editor v-model="editorContent" />
            </el-col>
            <el-col class="card-actions" :span="24">
              <el-button type="primary" @click="handleUpdateUnitInfo">
                {{ $t('page_unit_edit.btn_update') }}
              </el-button>
              <el-button @click="goToUnitPage">
                {{ $t('page_unit_edit.btn_cancel') }}
              </el-button>
            </el-col>
          </el-row>
        </template>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import VTextEditor from '../../../components/ui/VTextEditor'
import {mapState} from 'vuex'
import {
  updateUnitCardInfo,
  fetchUnitCardInfo
} from '../../../client/unit-page/services/unit-sevice'

import {UNIT_ABOUT_ROUTE_NAME} from '../../../shared/constants/app-routes-names'
import CurrentUnitInfo from '../components/current-unit-info/CurrentUnitInfo'

export default {
  name: 'UnitEditDescription',
  components: {
    CurrentUnitInfo,
    VTextEditor
  },
  data() {
    return {
      editorContent: ''
    }
  },
  computed: {
    ...mapState('loadingState', {
      isLoading: state => state.isLoading
    })
  },
  created() {
    this.fetchEditorContent()
  },
  methods: {
    fetchEditorContent() {
      const { unitID } = this.$route.params
      fetchUnitCardInfo(unitID)
        .then((res) => {
          this.editorContent = res
        })
        .catch((e) => {
          this.$message({
            showClose: false,
            type: 'error',
            message: `${this.$t(e)}`
          })
        })
    },
    handleUpdateUnitInfo() {
      if (!this.editorContent.trim()) {
        this.$message({
          showClose: false,
          type: 'error',
          message: `${this.$t('validators.cannot_be_empty')}`
        })
        return
      }

      const { unitID } = this.$route.params
      updateUnitCardInfo(unitID, this.editorContent)
      .then(() => {
        this.goToUnitPage()
        this.$message({
          showClose: false,
          type: 'success',
          message: `${this.$t('page_unit_edit.messages.edit_success')}`
        })
      })
      .catch((e) => {
        this.$message({
          showClose: false,
          type: 'error',
          message: `${this.$t(e)}`
        })
      })
    },
    goToUnitPage() {
      const { unitID } = this.$route.params
      this.$router.push({
        name: UNIT_ABOUT_ROUTE_NAME,
        params: {
          unitID
        }
      })
    }
  }
}
</script>

<style scoped>
  .card-actions {
    margin-top: 1.5em;
  }
</style>
